import React from "react";

// Customizable Area Start
import {
  Box,
  IconButton,
  MenuItem,
  Avatar,
} from "@material-ui/core";
import { CustomTypography, StyledEditDeleteMenu } from "../../../components/src/MuiCustomComponents";
import {MoreVert, Check} from '@material-ui/icons';
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNotificationMenu = () => {
    const { notificationMenu } = this.state;
    return (
       <Box>
          <IconButton className="notification_menu" onClick={(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleMenuClick(event)}>
             <MoreVert style={webStyle.menuIcon} />
          </IconButton>
          <StyledEditDeleteMenu
             anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
             }}
             transformOrigin={{
                vertical: "top",
                horizontal: "right",
             }}
             style={{marginTop:"3rem",marginLeft:"1.3rem"}}
             anchorEl={notificationMenu}
             keepMounted
             className = 'menu_button'
             open={Boolean(notificationMenu)}
             bcgColor={'#6C7278'}
             onClose={()=>this.handleMenuClose()}
          >
             <MenuItem style={webStyle.menuItemStyle} >
                <Box style={{color:'white'}}><Check/></Box>
                <Box>Mark all as read</Box>
             </MenuItem>
          </StyledEditDeleteMenu>
       </Box>
    )
 }


  renderNotificationHeader=()=>{
    return <Box style={webStyle.notiContainer}>
       <CustomTypography style={{color:"white"}}>Notifications</CustomTypography>
       {this.renderNotificationMenu()}
    </Box>
  }

  renderNotificationContainer=()=>{
    const messageArray = [false,true,true,true];
    return <Box style={{borderRadius:"10px",overflow:'hidden'}}>
      {messageArray.map((message)=>this.renderEachNotification(message))}
    </Box>
  }

  renderEachNotification=(isRead:boolean)=>{
    
    return <Box style={{...webStyle.eachNotificationContainer,borderBottom:'1px solid #3E4247'}}>
       <Box style={{...webStyle.avatarMessageContainer,width:"100%"}}>
           <Avatar />
           <Box style={{rowGap:'0.6rem',display:"flex",flexDirection:'column',width:"100%"}} >

           <Box style={webStyle.nameText}>
              <Box style={webStyle.textContainer}>
                <Box fontWeight={'bold'} color={isRead ? '#FFD27E' : ''}>Megan - Customer support </Box>
                <Box fontWeight={'bold'} color={isRead ? '#FFD27E' : '#8795A8'}>| 3h </Box>
                {isRead && this.renderDot()}
              </Box>
              {this.renderReadUnred(isRead)}
           </Box> 

            <Box style={{...webStyle.dullText,wordBreak:"break-all"}}>Megan: The order has been double checked. Indeed is arriving today</Box>
           </Box>
       </Box>
   
    </Box>
  }

  renderDot=()=>{
    return <Box style={webStyle.dot} />
  }

  renderReadUnred=(isRead:boolean)=>{ 
    const handleText = isRead ? "Mark as read" : "Mark as unread";
    const color = isRead ? 'white' : '#94A3B8'
    return <Box>
        <Box style={{...webStyle.avatarMessageContainer,columnGap:'0.5rem'}}>
          <Check style={{color}}/> 
          <Box color={color}>{handleText}</Box>
       </Box>
    </Box>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className="booking_section notification_container">
        {this.renderNotificationHeader()}
        {this.renderNotificationContainer()}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
type CSSPropertiesMap = {
  [key: string]: React.CSSProperties;
};

const webStyle:CSSPropertiesMap = {
  notiContainer:{
    display:"flex",
    justifyContent:'space-between',
    alignItems : "center"
  },
  menuIcon : {
    color:'white',
    cursor:'pointer',
    position:'relative'
  },
  menuItemStyle:{
    fontSize:'1.2rem',
    columnGap : '0.4rem',
    backgroundColor:'#6C7278'
  },
  eachNotificationContainer:{
    backgroundColor:"#2D3034",
    padding:'1rem',
    color:'white'
  },
  avatarMessageContainer:{
    display:"flex",
    columnGap : '1rem',
    alignItems : 'center',
  },
  nameText:{
    display:'flex',
    justifyContent:'space-between'
  },
  dot:{
    borderRadius:"50%",
    width:"0.65rem",
    height:"0.65rem",
    backgroundColor:"#FFD27E",
    position:'relative',
    marginTop:'0.14rem'
  },
  textContainer:{
    display : 'flex',
    columnGap : '0.5rem',
    alignItems : 'center'
  },
  dullText : {
    color : '#8795A8'
  },
  lightText : {
    color : '#FFD27E'
  }
};
// Customizable Area End
